<template lang="pug">
  .iconList.mainContainer
    h2 HELP
    .iconDescription
      .iconDescriptionContainer
        h3 Add Memo 
        IconBase(width="50" height="50" iconName="Add" iconColor="#777777")
          IconAdd
      .iconDescriptionContainer
        h3 Edit Memo
        IconBase(width="50" height="50" iconName="Edit" iconColor="#777777")
          IconEdit
      .iconDescriptionContainer
        h3 Copy to Clipboard
        IconBase(width="50" height="50" iconName="Copy" iconColor="#777777")
          IconCopy
      .iconDescriptionContainer
        h3 Delete Memo
        IconBase(width="50" height="50" iconNam:e="Delete" iconColor="#777777")
          IconDelete
      .iconDescriptionContainer
        h3 Change to "Browse Mode"
        IconBase(width="50" height="50" iconName="Exit" iconColor="#777777")
          IconExit
      .iconDescriptionContainer
        h3 Change to "Sort Mode"
        IconBase(width="50" height="50" iconName="Sort" iconColor="#777777")
          IconSort
      .iconDescriptionContainer
        h3 Open "Mode Change" Modal
        IconBase(width="50" height="50" iconName="ModeSelect" iconColor="#777777")
          IconModeSelect
      .iconDescriptionContainer
        h3 Check "Registered Tags"
        IconBase(width="50" height="50" iconName="RegisteredTags" iconColor="#777777")
          IconTagList

</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconEdit from "@/components/icons/IconEdit.vue"
import IconAdd from "@/components/icons/IconAdd.vue"
import IconCopy from "@/components/icons/IconCopy.vue"
import IconDelete from "@/components/icons/IconDelete.vue"
import IconExit from "@/components/icons/IconExit.vue"
import IconSearch from "@/components/icons/IconSearch.vue"
import IconSort from "@/components/icons/IconSort.vue"
import IconModeSelect from "@/components/icons/IconModeSelect.vue"
import IconTagList from "@/components/icons/IconTagList.vue"
export default {
  name: 'help',
  components: {
    IconBase,
    IconEdit,
    IconAdd,
    IconCopy,
    IconDelete,
    IconExit,
    IconSearch,
    IconSort,
    IconModeSelect,
    IconTagList
  }
}
</script>
<style scoped lang="stylus">
.iconList
  width: 90%
  margin: 0 auto
  padding: 60px 0px 0px 0px
  .iconDescription
    display: flex
    flex-wrap: wrap
    .iconDescriptionContainer
      margin-top: 10px
      width: 50%
      h3
        line-height: 3em
        font-size: 0.8rem!important
  h2
    font-size: 1.5rem
    font-weight: bolder
    line-height: 3em
    letter-spacing: 0.2em
</style>